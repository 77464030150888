import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DatasourceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/datasource-interfaces/datasource.interface';
import { InteroperabilityRecordBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/interoperability-interfaces/interoperability.interface';
import { ProviderBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/provider-interfaces/provider.interface';
import { ServiceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/service-interfaces/service.interface';
import { TrainingResourceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/training-resource-interfaces/training-resource.interface';
import { VocabularyType } from '../../../features/dashboard/contributors-dashboard/shared/services/vocabulary.enum';
import { Vocabulary } from '../../../features/dashboard/contributors-dashboard/interfaces/vocabulary.interface';
import { ServiceType } from '../../../features/dashboard/contributors-dashboard/interfaces/offer-interfaces/serviceType.interface';
import { Offer } from '../../../features/dashboard/contributors-dashboard/interfaces/offer-interfaces/offer.interface';

export const ContributorsDashboardActions = createActionGroup({
  source: 'ContributorsDashboard',
  events: {
    // For GENERAL
    'Load Contributor Mode': emptyProps(),
    'Update Contributor Mode': props<{ value: boolean }>(),
    'Clear Contributor Mode': emptyProps(),

    'Load Contributor': props<{ value: any }>(),
    'Update Contributor': props<{ value: any }>(),
    'Clear Contributor': emptyProps(),

    'Load Contributors': props<{ from: number; query: string }>(),
    'Load Contributors Success': props<{ results: ProviderBundle[]; total: number; from: number }>(),
    'Clear Contributors': emptyProps(),

    'Load Service': props<{ value: any }>(),
    'Update Service': props<{ value: any }>(),
    'Clear Service': emptyProps(),

    'Load Services': props<{ contributor: string }>(),
    'Load Services Success': props<{ value: ServiceBundle[] }>(),
    'Clear Services': emptyProps(),

    'Load Service Datasource': emptyProps(),
    'Update Service Datasource': props<{ value: ServiceBundle }>(),
    'Clear Service Datasource': emptyProps(),

    'Load Datasource': emptyProps(),
    'Update Datasource': props<{ value: DatasourceBundle }>(),
    'Clear Datasource': emptyProps(),

    'Load Datasources': props<{ service: string }>(),
    'Load Datasources Success': props<{ value: DatasourceBundle[] }>(),
    'Clear Datasources': emptyProps(),

    'Load Training Resource': emptyProps(),
    'Update Training Resource': props<{ value: TrainingResourceBundle }>(),
    'Clear Training Resource': emptyProps(),

    'Load Training Resources': props<{ contributor: string }>(),
    'Load Training Resources Success': props<{ value: TrainingResourceBundle[] }>(),
    'Clear Training Resources': emptyProps(),

    'Load Interoperability Record': emptyProps(),
    'Update Interoperability Record': props<{ value: InteroperabilityRecordBundle }>(),
    'Clear Interoperability Record': emptyProps(),

    'Load Interoperability Records': props<{ contributor: string }>(),
    'Load Interoperability Records Success': props<{ value: InteroperabilityRecordBundle[] }>(),
    'Clear Interoperability Records': emptyProps(),

    'Load Vocabularies': props<{ vocabularyType: VocabularyType }>(),
    'Load Vocabularies Cached': emptyProps(),
    'Load Vocabularies Success': props<{ vocabularyType: VocabularyType; vocabulary: Vocabulary[] }>(),
    'Clear Vocabularies': emptyProps(),

    'Load Service Type': emptyProps(),
    'Load Service Type Cached': emptyProps(),
    'Load Service Type Success': props<{ serviceType: ServiceType }>(),
    'Clear Service Type': emptyProps(),

    'Load Service Offers': props<{ serviceId: string }>(),
    'Load Service Offers Cached': emptyProps(),
    'Load Service Offers Success': props<{ serviceOffers: Offer[] }>(),
    'Clear Service Offers': emptyProps(),
  },
});
